import { get_cookie, set_cookie } from 'js/utils';

const save_duration = 24 * 30; // 1 month

export default class Save_Items {

    constructor( options = {} ){
        this.options = options;
        this.init();
    }

    init(){
        this.key = 'save_items_'+ this.options.key;
        document.body[this.key] = this;
        this.get_items();
        this.update_listener();
        this.buttons_init();
        this.count_init();
    }

    buttons_init(container = document){
        
        container.querySelectorAll(this.options.add_item_button).forEach(button=>{

            if( typeof button.save_item_init !== 'undefined' ) return;
            button.save_item_init = 1;

            let id = button.dataset.id;
            if( !id ) return;
            id = parseInt(id);

            if( typeof button.dataset.removeText !== 'undefined' ) {
                button.initial_text = button.textContent;
            }
            
            // selected
            if( this.items.indexOf(id) !== -1 ) {
                button.classList.add('added');
                if( typeof button.dataset.removeText !== 'undefined' ) {
                    button.textContent = button.dataset.removeText;
                }
            }

            button.addEventListener('click',()=>{

                console.log('button click', button)

                let added = button.classList.contains('added');
                if( added ) {
                    // remove
                    this.remove_item(id);
                    button.classList.remove('added');
                    if( typeof button.dataset.removeText !== 'undefined' ) {
                        button.textContent = button.initial_text;
                    }
                }
                else {
                    // add
                    if( this.add_item(id) ) {
                        button.classList.add('added');
                        if( typeof button.dataset.removeText !== 'undefined' ) {
                            button.textContent = button.dataset.removeText;
                        }
                    }
                }
            });
        })
    }

    add_item(id){

        if( this.check_limit() ) {
            return false;
        }

        if( this.items.indexOf(id) !== -1 ) {
            return false; // already added
        }
        this.items.push(id);
        this.update_items(id, 'add');

        return true;
    }

    remove_item(id){
        this.items.splice(this.items.indexOf(id), 1);
        this.update_items(id, 'remove');

        return true;
    }

    get_items(){
        this.items = get_cookie(this.key);
        if( !this.items ) {
            this.items = [];
        }
        else {
            this.items = JSON.parse(this.items);
        }
    }

    update_items(id, type){

        set_cookie(this.key, JSON.stringify(this.items), 24*30);

        document.body.dispatchEvent(
            new CustomEvent('save_items_update',
            {
                detail: {
                    key: this.key,
                    items: this.items,
                    id,
                    type,
                }
            }
        ));
    }
    
    update_listener(){
        document.body.addEventListener('save_items_update', e=>{
            if( e.detail.key != this.key ) return;
            this.update_total_count();
        })
    }

    count_init(){
        let element = this.options.total_count_element;
        if( typeof element === 'string' ) {
            element = document.querySelector(element);
        }
        if( !element ) return;

        this.count_element = document.createElement('span');
        this.count_element.classList.add('count');
        element.append(this.count_element);
        
        this.update_total_count();
    }

    update_total_count(){
        if( typeof this.count_element === 'undefined' ) return;
        this.count_element.dataset.count = this.items.length;
        this.count_element.textContent = this.items.length;
    }

    set_items(items){
        this.items = items;
        set_cookie(this.key, JSON.stringify(this.items), save_duration);
        this.update_total_count();
    }

    check_limit(){

        if( typeof this.options.limit === 'undefined' || !this.options.limit ) {
            return false;
        }

        if( this.items.length >= this.options.limit ) {
            let limit_reached_message = this.options.limit_message ?? `Limit reached, you can only add ${this.options.limit} items`;
            alert(limit_reached_message);
            return true;
        }

        return  false;
    }
}