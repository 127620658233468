import { on_ready, is_elementor_edit, queue_tasks } from 'js/utils';
import { retina_init } from 'js/functions';
import Save_Items from 'classes/Save_Items';
import Toggle_Height from 'classes/Toggle_Height';
import header_init from 'modules/header/header';
import sticky_footer_cta_init from 'modules/footer/sticky_footer_cta/sticky_footer_cta';
import forms_init from 'modules/forms/forms';
import carousels_init from 'modules/carousel/carousel';

// load global styles
import './main.scss';

window.ff = {};

on_ready(()=>{

    if( is_elementor_edit() ) {
        // elementor edit screen: do not run scripts
        return;
    }
    
    queue_tasks([

        // sticky header
        header_init,

        // sticky footer
        sticky_footer_cta_init,

        // forms ui + gravityforms customizations
        forms_init,

        // swiper carousel
        carousels_init,

        // load @2x images
        retina_init,
        
        // toggle element height from 0 to default - for animating height
        toggle_height_init,

        // mobile related functionalities
        on_mobile,
    ]);
    
    // slide menu: remove if not used
    if( document.querySelector('.ff_slide_menu') ) {
        import('modules/menu/slide_menu/slide_menu');
    }

    // search popup: remove if not used
    if( document.querySelector('.search_popup') ) {
        import('modules/search/search_popup/search_popup');
    }

    // cookie consent
    // if( !getCookie('ff_cookie_consent') ) {
    //     import('modules/cookie_consent/simple/simple')
    // }

    // wishlist - remove if not used
    window.ff.wishlist = new Save_Items({
        key: 'wishlist',
        total_count_element: '.wishlist_count',
        add_item_button: '.wishlist_add',
    });

    // compare - remove if not used
    window.ff.compare = new Save_Items({
        key: 'compare',
        total_count_element: '.compare_count',
        add_item_button: '.compare_add',
        limit: 3,
        limit_message: 'You can only add up to 3 items to compare',
    });
    
});

function toggle_height_init(){
    document.querySelectorAll('.ff_toggle_height').forEach(container=>{
        
        if( typeof container.ff_toggle_height !== 'undefined' ) return;

        const trigger = container.querySelector('.ff_toggle_height_trigger');
        const target = container.querySelector('.ff_toggle_height_target');
        if( !trigger || !target ) return;
        
        container.ff_toggle_height = new Toggle_Height({
            trigger,
            target,
        });

    })
}

function on_mobile(){
    if( window.innerWidth > 768 ) return;
    close_footer_dropdowns();
}

function close_footer_dropdowns(){
    document.querySelectorAll('.elementor-location-footer .ff_toggle_height_trigger.open').forEach(dropdown=>{
        dropdown.click();
    })
}